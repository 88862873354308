// Import CSS
// import '../css/gijgo.min.css';
// import '../css/theme.default.min.css';
// import '../css/theme.dark.min.css';
// import '../css/theme.bootstrap_4.min.css';
// import './addons/pager/jquery.tablesorter.pager.css';
import 'simplelightbox/dist/simple-lightbox.css';
// import 'datatables.net/css/datatables.min.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-fixedheader-bs5/css/fixedHeader.bootstrap5.min.css';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
// import 'dropzone/dist/dropzone.css';
import '../bootstrap/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/myStyles.css';
// Import JS
import * as bootstrap from 'bootstrap';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './fonts.js';
// import * as feather from 'feather-icons'; // if not imported with webpack.ProvidePlugin
// import './font-awesome-5.11.2-all.min.js';
// import Chart from 'chart.js/auto';
import './jquery.html5storage.min.js';
// import './datatables.min.js';
// import '../demo/chart-area-demo.js';
// import '../demo/chart-bar-demo.js';
// import '../demo/chart-pie-demo.js';
// import '../demo/datatables-demo.js';
// import './theme.min.js';
const datatablesFrench = require('./datatables_fr_FR.json');
import jszip from 'jszip';
window.JSZip = jszip;
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import 'datatables.net';
import 'datatables.net-bs5';
// import 'datatables.net-autofill-bs5';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-colreorder-bs5';
// import 'datatables.net-fixedcolumns-bs5';
import 'datatables.net-fixedheader-bs5';
// import 'datatables.net-keytable-bs5';
// import 'datatables.net-responsive-bs5';
// import 'datatables.net-rowgroup-bs5';
// import 'datatables.net-rowreorder-bs5';
// import 'datatables.net-scroller-bs5';
import 'datatables.net-searchbuilder-bs5';
// import 'datatables.net-searchpanes-bs5';
// import './jquery.easing.min.js';
// import './gijgo.fr.min.js';
// import './jquery.tablesorter.min.js';
// import './jquery.tablesorter.widgets.min.js';
// import './widget-cssStickyHeaders.min.js';
// import './addons/pager/jquery.tablesorter.pager.min.js';
// import './lightbox.rotate.img.js';
// import './smooth.scroll.anchor.js';
// import './FileSaver.min.js';
// import 'tableexport';
// import Dropzone from 'dropzone';
import SimpleLightbox from "simplelightbox";
// import simpleLightbox from "simpleLightbox/dist/simple-lightbox.jquery";
import regeneratorRuntime from "regenerator-runtime"; // Async/Await made available here
import 'litepicker';
import 'litepicker/dist/plugins/ranges';
// import 'litepicker/dist/plugins/mobilefriendly';
import langages from '../lang/langages.json';


// Set new default font family and font color to mimic Bootstrap's default styling
// Chart.defaults.global.defaultFontFamily = 'Metropolis, -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
// Chart.defaults.global.defaultFontColor = "#858796";

var globals,
App = {
	settings: {
		defLatLng: [48.86, 2.33],
		defZoom: 6,
		bottomScrolled: false,
		myPage: '',
		isMobile: false,
		serverAddress: "https://pmc.ozena.fr/db.php",
		serverBaseUrl: "https://pmc.ozena.fr/",
		rmbRate: 7.5,
		year: new Date().getFullYear(),
		today: new Date(),
		tomorrow: new Date(new Date().setDate(new Date().getDate() + 1)),
		nextSevenDays: new Date(new Date().setDate(new Date().getDate() + 7)),
		nextThirtyDays: new Date(new Date().setDate(new Date().getDate() + 30)),
		thisMonthStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
		thisMonthEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
		nextMonthStart: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
		nextMonthEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0),
		myLineChart: false,
		pass: $.localStorage.getItem('pass'),
		login: $.localStorage.getItem('login'),
		pwd: $.localStorage.getItem('pwd'),
		name: $.localStorage.getItem('name'),
		firstname: $.localStorage.getItem('firstname'),
		phone: $.localStorage.getItem('phone'),
		id: $.localStorage.getItem('id'),
		active: $.localStorage.getItem('active'),
		type: $.localStorage.getItem('type'),
		company: $.localStorage.getItem('company'),
		role: $.localStorage.getItem('role'),
		auto_c: $.localStorage.getItem('auto_c'),
		name_c: $.localStorage.getItem('name_c'),
		company_c: $.localStorage.getItem('company_c'),
		address_c: $.localStorage.getItem('address_c'),
		address2_c: $.localStorage.getItem('address2_c'),
		zip_c: $.localStorage.getItem('zip_c'),
		city_c: $.localStorage.getItem('city_c'),
		country_c: $.localStorage.getItem('country_c'),
		contact_c: $.localStorage.getItem('contact_c'),
		phone_c: $.localStorage.getItem('phone_c'),
		mail_c: $.localStorage.getItem('mail_c'),
		siret_c: $.localStorage.getItem('siret_c'),
		tva_c: $.localStorage.getItem('tva_c'),
		adminPass: $.sessionStorage.getItem('adminPass'),
	},
	refreshGlobals: function(data) {
		globals.pass = data.pass;
		globals.login = data.login;
		globals.pwd = data.pwd;
		globals.name = data.name;
		globals.firstname = data.firstname;
		globals.phone = data.phone;
		globals.id = data.id;
		globals.active = data.active;
		globals.type = data.type;
		globals.mail = data.mail;
		globals.company = data.company;
		globals.role = data.role;
		for (const [key, value] of Object.entries(data.clients)) {
			globals.key = value;
		}
		globals.adminPass = data.adminPass;
	},
	init: function() {
		// kick things off
		globals = this.settings;
		$("#now-date").append(globals.year);
		this.bindUIActions();
		/*
		fetch(langages)
		.then((res) => res.json())
		.then((data) => {
			globals.langages = data;
			// console.log(globals.langages);
		})
		.then(() => {
			this.bindUIActions();
			App.setLangages();
		});
		*/
	},
	setLangages: function() {
		if(globals.myLang) $('#currentLangIcon').attr('src', 'img/assets/img/lang/'+globals.myLang+'.png');
		// Here every key in each langage should reflect to the data-lang property of the element it stranslates (<span data-lang="the_json_key"></span>)
		switch(globals.myLang) {
			case 'en':
				$.each(globals.langages.data.en, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			break;
			case 'ge':
				$.each(globals.langages.data.ge, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			break;
			case 'sp':
				$.each(globals.langages.data.sp, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			break;
			case 'it':
				$.each(globals.langages.data.it, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			break;
			case 'nl':
				$.each(globals.langages.data.nl, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			break;
			case 'pt':
				$.each(globals.langages.data.pt, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			break;
			default:
				$.each(globals.langages.data.fr, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			}
	},
	changeLangage: function(chosenLangage) {
		globals.myLang = chosenLangage;
		$.localStorage.setItem('myLang', chosenLangage);
		// $('#currentLangIcon').attr('src', 'img/assets/img/lang/'+chosenLangage+'.png');
		this.setLangages();
	},
	checkAccountStatus: function () {
		$.post(globals.serverAddress, {id: $.localStorage.getItem('id'), login: $.localStorage.getItem('login'), pwd: $.localStorage.getItem('pwd'), req: 'checkAccountStatus'}, function(data){
			if(data.check!="GO") {
				// App.logMeOut();
				alert("Il y a un problème avec votre compte, veuillez réessayer plus tard\r\nSi vous pensez qu'il s'agit d'une erreur, veuillez nous contacter.");
			}
			else {
				// Every things is ok so we refresh globals and we kick things off...
				$.localStorage.setItem('pass', data.pass);
				$.localStorage.setItem('login', data.login);
				$.localStorage.setItem('pwd', data.pwd);
				$.localStorage.setItem('name', data.name);
				$.localStorage.setItem('firstname', data.firstname);
				$.localStorage.setItem('phone', data.phone);
				$.localStorage.setItem('company', data.company);
				$.localStorage.setItem('id', data.id);
				$.localStorage.setItem('active', data.active);
				$.localStorage.setItem('type', data.type);
				$.localStorage.setItem('mail', data.mail);
				$.localStorage.setItem('role', data.role);
				for (const [key, value] of Object.entries(data.clients)) {
					$.localStorage.setItem(key, value);
				}
				$.sessionStorage.setItem('adminPass', data.adminPass);
				// globals.adminPass = data.adminPass;
				setTimeout(function(){
					App.refreshGlobals(data);
				}, 100);
				setTimeout(function(){
					App.kickThingsOffOnceLoggedIn();
				}, 200);
			}
		}, "json").fail(function(){
			App.logMeOut();
			alert("Suite à un problème technique nous ne pouvons vérifier votre compte, veuillez réessayer plus tard\r\nSi l'erreur persiste, veuillez nous contacter.");
		});
	},
	logMeIn: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Please wait');
		$(myFormDiv).closest('.row').css('opacity', 0.6);
		let query = $(myFormDiv).serialize();
		const req = "login";
		query = query + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			if(data.pass == "OK") {
				$.localStorage.setItem('pass', data.pass);
				$.localStorage.setItem('login', data.login);
				$.localStorage.setItem('pwd', data.pwd);
				$.localStorage.setItem('name', data.name);
				$.localStorage.setItem('firstname', data.firstname);
				$.localStorage.setItem('phone', data.phone);
				$.localStorage.setItem('company', data.company);
				$.localStorage.setItem('id', data.id);
				$.localStorage.setItem('active', data.active);
				$.localStorage.setItem('type', data.type);
				$.localStorage.setItem('mail', data.mail);
				$.localStorage.setItem('role', data.role);
				for (const [key, value] of Object.entries(data.clients)) {
					$.localStorage.setItem(key, value);
				}
				$.sessionStorage.setItem('adminPass', data.adminPass);
				// globals.adminPass = data.adminPass;
				setTimeout(function(){
					App.refreshGlobals(data);
				}, 100);
				setTimeout(function(){
					if(data.type=='manager') document.location.href='/delivery';
					else document.location.href='./';
				}, 1000);
			}
			else {
				if(data.active==0) alert("This account has been deactivated !");
				else alert("Wrong login or password !");
			}
		}, "json").always(function() {
			$(myFormDiv+' #sender').attr("disabled", false).html('<b><i class="fa fa-unlock"></i> Log in</b>');
			$(myFormDiv).closest('.row').css('opacity', 1);
		});
	},
	logMeOut: function () {
		$.localStorage.removeItem('pass');
		$.localStorage.removeItem('login');
		$.localStorage.removeItem('pwd');
		$.localStorage.removeItem('name');
		$.localStorage.removeItem('firstname');
		$.localStorage.removeItem('phone');
		$.localStorage.removeItem('id');
		$.localStorage.removeItem('company');
		$.localStorage.removeItem('active');
		$.localStorage.removeItem('type');
		$.localStorage.removeItem('mail');
		$.localStorage.removeItem('role');
		$.localStorage.removeItem('auto_c');
		$.localStorage.removeItem('name_c');
		$.localStorage.removeItem('company_c');
		$.localStorage.removeItem('address_c');
		$.localStorage.removeItem('address2_c');
		$.localStorage.removeItem('zip_c');
		$.localStorage.removeItem('city_c');
		$.localStorage.removeItem('country_c');
		$.localStorage.removeItem('contact_c');
		$.localStorage.removeItem('phone_c');
		$.localStorage.removeItem('mail_c');
		$.localStorage.removeItem('siret_c');
		$.localStorage.removeItem('tva_c');
		setTimeout(function(){
			document.location.href='/login';
			// document.location.href='https://pmc.ozena.fr';
		}, 1000);
	},
	subContact: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Please wait');
		let query = $(myFormDiv).serialize();
		const req = "contact";
		query = query + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok")
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-2x fa-check-circle me-1"></i>Votre message a bien été envoyé.</b></div>';
			else
				returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-2x fa-times-circle me-1"></i>Votre message n\'a pas été envoyé.</b></div>';
			$(myFormDiv+' #sender').attr("disabled", false).html('Envoyer&nbsp;<i class="fa fa-paper-plane"></i>');
			$(myFormDiv+' #successfail').empty().append(returns);
		}, "json");
	},
	addWasValidatedClass: function (myFormDiv) {
		$(myFormDiv).addClass('was-validated');
		var wrongFields = "The following fields are not properly filled: \n";
		var wrongPop = false;
		$(myFormDiv).find(':invalid').each(function () {
			//alert("IN");
			wrongPop = true;
			$(this).closest('.form-group').find('label').not('.notValidatedClass').each(function () {
				const fieldLabel = $(this).text();
				if(fieldLabel!='') wrongFields += fieldLabel+"\n";
			});
		});
		if(wrongPop) alert(wrongFields);
		return !wrongPop;
	},
	clearFormFields: function(myFormDiv, event) {
		// if(myFormDiv=='#editPicturesAddressForm') event.preventDefault(); // prevents form submission when button is inside it !
		$(myFormDiv).find("input[type=text], input[type=tel], input[type=email], input[type=number], input[type=file], input[type=password], textarea, select").val('');
		$(myFormDiv).find("input[type=checkbox]").prop("checked", false);
		$(myFormDiv).find("input[type=radio]").prop("checked", false);
		$(myFormDiv).removeClass('was-validated');
		$(myFormDiv+' #successfail').empty();
	},
	safeJsonParse: function(input) {
		try {
			return JSON.parse(input);
		} catch (e) {
			return undefined;
		}
	},
	urlParam: function(name, url){
		// Get parameters from an URL
		var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
		//For current URL
		//var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
		if (results==null){
			return null;
		}
		else{
			return results[1] || 0;
		}
	},
	bindUIActions: function() {
		// Connected or not
		if(globals.pass == "OK") {
			App.checkAccountStatus();
			// App.kickThingsOffOnceLoggedIn();
		}
		// Is it Mobile device
		if(/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) || window.innerWidth<992) globals.isMobile = true;
		if(globals.isMobile) {
			$('#myTab').removeClass('nav-tabs').addClass('nav-pills');
		}
		$('.expends').on("click", function () {
			$(this).next('div').slideToggle('slow');
			//$.mobile.silentScroll($(this).next('div').offset().top);
		});
		// Managing navigation so that we bindUIActions on the right page...
		let url = window.location.pathname;
		globals.myPage = url.substring(url.lastIndexOf('/')+1);
		$.sessionStorage.setItem('myPage', globals.myPage);
		// Smooth Scroll to div...
		App.refreshSmoothScroll();
		// Litepicker Documentation : https://wakirin.github.io/Litepicker
		/*
		const litepickerRangePlugin = document.getElementById('litepickerRangePlugin');
		if (litepickerRangePlugin) {
			new Litepicker({
				element: litepickerRangePlugin,
				startDate: new Date(),
				endDate: new Date(),
				singleMode: false,
				numberOfMonths: 2,
				numberOfColumns: 2,
				format: 'MMM DD, YYYY',
				lang: 'fr-FR',
				plugins: ['ranges'],
				ranges: {
					customRangesLabels:["Aujourd'hui","Hier","Derniers 7 Jours","Derniers 30 jours","Ce Mois","Dernier Mois"],
				},
			});
		}
		const litepickerSingleDate = document.getElementById('litepickerSingleDate');
		if (litepickerSingleDate) {
			new Litepicker({
				element: litepickerSingleDate,
				format: 'MMM DD, YYYY',
				lang: 'fr-FR',
			});
		}
		const litepickerDateRange = document.getElementById('litepickerDateRange');
		if (litepickerDateRange) {
			new Litepicker({
				element: litepickerDateRange,
				singleMode: false,
				format: 'MMM DD, YYYY',
				lang: 'fr-FR',
			});
		}
		const litepickerDateRange2Months = document.getElementById('litepickerDateRange2Months');
		if (litepickerDateRange2Months) {
			new Litepicker({
				element: litepickerDateRange2Months,
				singleMode: false,
				numberOfMonths: 2,
				numberOfColumns: 2,
				format: 'MMM DD, YYYY',
				lang: 'fr-FR',
			});
		}
		*/
		document.addEventListener("scroll", function (event) {
			if (App.getDocHeight() == App.getScrollXY()[1] + window.innerHeight) {
				//$('.go-up-fixed').fadeOut('slow');
				globals.bottomScrolled=true;
			}
			else {
				globals.bottomScrolled=false;
				if(App.getScrollXY()[1] == 0) {
					$('.go-up-fixed').fadeOut('slow');
				}
				else
					$('.go-up-fixed').fadeIn('slow');
			}
		});
        /*
		// On Enter search...
		$('#keywords').keydown(function(e) {
			var keywords = $('#keywords').val();
			if (e.keyCode === 13) {
				App.search();
			}
		});
		// Continuous search...
		$('#keywords').keyup(function() {
			var keywords = $('#keywords').val();
			if(keywords.length > 6) {
				App.search();
			} else {
				// doNothing !
			}
		});
		*/
	},
	kickThingsOffOnceLoggedIn: function()
	{
		// Display .adminOnly, .accountantOnly... Objects
		switch (globals.type) {
			case 'MyBelovedLord':
				$(".adminOnly").show();
				$(".adminOnlyFlex").css('display', 'flex');
				$(".adminHide").hide();
			break;
			case 'manager':
				$(".managerOnly").show();
				$(".managerOnlyFlex").css('display', 'flex');
				$(".managerHide").hide();
				$('.allUsersRemove').remove();
				if(globals.myPage!='delivery') document.location.href='/delivery';
			break;
			case 'user':
				$(".userOnly").show();
				$(".userOnlyFlex").css('display', 'flex');
				$(".userHide").hide();
				$('.allUsersRemove').remove();
			break;
		}
		// if(globals.avatar != '') $("#avatarNavCont").html('<img class="img-fluid" src="'+globals.serverBaseUrl+'docs/avatars/'+globals.avatar+'" />');
		// else $("#avatarNavCont").html('<span class="dropdown-user-img text-center fs-2 text-white">'+globals.initials+'</span>');
		// $("#userInitialsNav").html(globals.initials);
		$(".profile-links").attr("href", "/users/"+globals.id);
		$('.my-account-name').empty().append(globals.name+' '+globals.firstname);
		$('.my-account-mail').empty().append(globals.login);
		$('#modal_contact #from_name').val(globals.name+' '+globals.firstname);
		$('#modal_contact #from_email').val(globals.login);
		$('#modal_contact #from_phone').val(globals.phone);
		$('#modal_contact #from_company').val(globals.company);
		/*
		const goTo = App.urlParam('goto', document.URL); // Where are we going
		const idBord = App.urlParam('id', document.URL); // Then get the id
		if(goTo == 1) App.fillModBordereaux(idBord, $("#modBordereauxBtn_"+idBord), '#modBordereauxForm', '#modBordereauxModal');
		if(globals.isMobile) $('#cotisationFramePop').closest('.embed-responsive').removeClass('embed-responsive-16by9').addClass('embed-responsive-1by1');
		if(goTo == 'pay') {
			// $('#cotisationFramePop').attr('src', 'https://gpi.snsolutions.fr/bo/pay/paypage.php?auto_u='+globals.id);
			if($('#cotisationFramePop').length) {
				const cotisationFramePop = document.getElementById('cotisationFramePop');
				cotisationFramePop.src += '?auto_u='+globals.id;
			}
			let myModal = new bootstrap.Modal(document.getElementById('cotisationModal'));
			myModal.show();
		}
		*/
	},
	getFormsSelectBoxes: async function(formsDivArray, myController, inputsArray) {
		let result;
		// Using try to get error handling, in order to have just the promise : const result = await $.post(...) would have done it.
		try {
			result = $.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, clientId: globals.auto_c, req: myController, action: 'getFormsSelectBoxes'}, function(data){
				if(formsDivArray.length>0 && inputsArray.length>0) {
					formsDivArray.forEach((formDiv, indexDiv) => {
						inputsArray.forEach((input, index) => {
							$(formDiv+" #"+input).empty().append(data[input]); // Can't use data.input here
							// console.log(formDiv+" #"+input+" | "+data[input]);
						});
					});
				}
			}, "json").always(function() {
			});
			return result;
		} catch (error) {
			console.error(error);
		}
	},
	popModal: function(myModalId) {
		const myModalObject = document.getElementById(myModalId);
		if (myModalObject) {
			let myModal = new bootstrap.Modal(myModalObject);
			myModal.show();
		}
		else {
			setTimeout(function() { // Little timer to let the system charge page if needed
				App.popModal(myModalId);
			}, 1000);
		}
	},
	refreshSmoothScroll: function() {
		// Smooth Scroll to div on anchors click...
		$('a[href*="#"]').not('a.noscroll').on('click', function (event) {
			event.preventDefault();
			let offset = 0;
			const target = this.hash;
			if($(this).data('offset') != undefined) offset = $(this).data('offset'); // if set data-offset="pixels"
			if($(target).length) {
				$('html, body').stop().animate({
					'scrollTop': $(target).offset().top - offset
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
			else { // Scrolls to top...
				$('html, body').stop().animate({
					'scrollTop': 0
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
		});
	},
	smoothScrollTo: function(target, offset) {
		// Smooth Scroll to div...
        if($(target).length) {
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - offset
            }, 1600, 'swing', function() {
                // window.location.hash = target;
            });
        }
	},
	number_format: function(number, decimals, dec_point, thousands_sep) {
		// *     example: App.number_format(1234.56, 2, ',', ' ');
		// *     return: '1 234,56'
		number = (number + "").replace(",", "").replace(" ", "");
		var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
			dec = typeof dec_point === "undefined" ? "." : dec_point,
			s = "",
			toFixedFix = function(n, prec) {
				var k = Math.pow(10, prec);
				return "" + Math.round(n * k) / k;
			};
		// Fix for IE parseFloat(0.55).toFixed(0) = 0;
		s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || "").length < prec) {
			s[1] = s[1] || "";
			s[1] += new Array(prec - s[1].length + 1).join("0");
		}
		return s.join(dec);
	},
	buildDataTables: function(myDiv, pageLength, colReorder, orderCol, orderWay, expTitle, filterColumsText, filterColumsSelect, myFilterField, myFilterCondition, myFilter, pdfExtraExportOptions, fixedHeader = true) {
		// Like : App.buildDataTables('#dataTable', 25, true, 4, 'desc', 'Titre', [0,1,2,3,4,5,6], '');
		// Or : App.buildDataTables('#dataTable', 25, true, 4, 'desc', 'Titre', [0,1,2,3,4,5,6], [7,8], 'Centre', '=', myFilter, [0,1,2,3,4,5,6]);
		// let fixedHeader = true;
		let fixedFooter = false;
		let headerOffset = 60;
		if(globals.isMobile) fixedHeader = false;
		// const dom = (globals.type=='user') ? '<"col-6 d-flex align-items-center"l><"col-6 d-flex align-items-center justify-content-end"f>rt<"col-12 col-md-6"i><"col-12 col-md-6"p>' : '<"col-6 col-md-4 d-flex align-items-center"l><"col-6 col-md-4 d-flex align-items-center justify-content-end justify-content-md-center"f><"col-12 col-md-4 d-flex align-items-center justify-content-end"B>rt<"col-12 col-md-6"i><"col-12 col-md-6"p>';
		const dom = '<"col-6 col-md-4 d-flex align-items-center"l><"col-6 col-md-4 d-flex align-items-center justify-content-end justify-content-md-center"f><"col-12 col-md-4 d-flex align-items-center justify-content-end"B>rt<"col-12 col-md-6"i><"col-12 col-md-6"p>';
		// Add row class to dataTable wrapper => Usefull to correcty display buttons using the dom option => Done in css directly on .dataTables_wrapper
		// $(myDiv+'_wrapper').addClass('row');
		// Add filter row in header...
		// $(myDiv+' thead tr').clone(true).addClass('filters').appendTo(myDiv+' thead');
		const pdfExtra = (typeof pdfExtraExportOptions !== 'undefined') ? pdfExtraExportOptions : '';
		const baseFiltering = (typeof myFilter !== 'undefined') ? myFilter : '';
		if (baseFiltering.length>0) {
			let tabFilter = new Array(myFilter);
			const myTable = $(myDiv).DataTable({
				destroy: true,
				dom: dom,
				// language: datatablesFrench,
				// language: {
				// 	url: globals.serverAddress+'datatables_fr_FR.php'
				// },
				pageLength: pageLength,
				lengthMenu: [ [5, 10, 25, 50, 100, -1], [5, 10, 25, 50, 100, "All"] ],
				colReorder: colReorder,
				fixedHeader: {header: fixedHeader, footer: fixedFooter, headerOffset: headerOffset},
				order: [[ orderCol, orderWay ]],
				buttons: [
					{text:'<li class="fa fa-2x fa-copy"></li>', title: expTitle, extend:'copy'},
					{text:'<li class="fa fa-2x fa-file-excel"></li>', title: expTitle, extend:'excel'},
					{text:'<li class="fa fa-2x fa-file-pdf"></li>', title: expTitle, exportOptions: {stripNewlines: false, columns: pdfExtra}, orientation: 'landscape', pageSize: 'LEGAL', extend:'pdf'},
					// {text:'<li class="fa fa-2x fa-file-pdf"></li>', title: 'SNS-GPI Liste des Bordereaux', orientation: 'landscape', pageSize: 'LEGAL', exportOptions: {columns:[0,1,2,3,5,6,7]}, extend:'pdf'},
					{text:'<li class="fa fa-2x fa-print"></li>', title: expTitle, exportOptions: {stripHtml: false, stripNewlines: false}, extend:'print'}
				],
				searchBuilder: {
					preDefined: {
						criteria: [
							{
								data: myFilterField,
								condition: myFilterCondition,
								value: tabFilter
							}
						]
					}
				},
				initComplete: function () {
					if(filterColumsText.length>0) {
						this.api().columns(filterColumsText).every( function () {
							let column = this;
							let input = $('<input class="form-control form-control-sm table-filter" type="text" placeholder="Rechercher" />')
							.appendTo( $(column.header()) )
							.off('keyup change')
							.on('keyup change', function (e) {
								e.stopPropagation();
								let val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
								let regexr = '({search})';
								column.search(val != '' ? regexr.replace('{search}', '(((' + val + ')))') : '', val != '', val == '').draw();
							});
						});
					}
					if(filterColumsSelect.length>0) {
						this.api().columns(filterColumsSelect).every( function () {
							let column = this;
							let filterDataArray = [];
							let select = $('<select class="form-select form-select-sm table-filter"><option value=""></option></select>')
								.appendTo( $(column.header()) )
								.on( 'change', function () {
									let val = $.fn.dataTable.util.escapeRegex(
										$(this).val()
									);
									column
										.search( val ? '^'+val+'$' : '', true, false )
										.draw();
								});
							column.data().unique().sort().each( function ( d, j ) {
								let filterData = d;
								if(d.indexOf('</') !== -1) { // In case there is html tags we strip those...
									filterData = ($(d).length) ? $(d).text() : d;
									// console.warn(d+' - '+$(d));
								}
								// select.append( '<option value="'+filterData+'">'+filterData+'</option>' );
								if(filterData!='') filterDataArray.push(filterData);
							});
							filterDataArray.sort();
							filterDataArray.forEach(element => {
								select.append( '<option value="'+element+'">'+element+'</option>' );
							});
						});
					}
				},
			});
			// myTable.buttons().container().appendTo(myDiv+'_wrapper .dataTable_filter');
		}
		else {
			const myTable = $(myDiv).DataTable({
				destroy: true,
				dom: dom,
				// language: datatablesFrench,
				// language: {
				// 	url: globals.serverAddress+'datatables_fr_FR.php'
				// },
				pageLength: pageLength,
				lengthMenu: [ [5, 10, 25, 50, 100, -1], [5, 10, 25, 50, 100, "All"] ],
				colReorder: colReorder,
				fixedHeader: {header: fixedHeader, footer: fixedFooter, headerOffset: headerOffset},
				order: [[ orderCol, orderWay ]],
				buttons: [
					{text:'<li class="fa fa-2x fa-copy"></li>', title: expTitle, extend:'copy'},
					{text:'<li class="fa fa-2x fa-file-excel"></li>', title: expTitle, extend:'excel'},
					{text:'<li class="fa fa-2x fa-file-pdf"></li>', title: expTitle, exportOptions: {stripNewlines: false, columns: pdfExtra}, orientation: 'landscape', pageSize: 'LEGAL', extend:'pdf'},
					{text:'<li class="fa fa-2x fa-print"></li>', title: expTitle, exportOptions: {stripHtml: false, stripNewlines: false}, extend:'print'}
				],
				initComplete: function () {
					if(filterColumsText.length>0) {
						this.api().columns(filterColumsText).every( function () {
							let column = this;
							let input = $('<input class="form-control form-control-sm table-filter" type="text" placeholder="Rechercher" />')
							.appendTo( $(column.header()) )
							.off('keyup change')
							.on('keyup change', function (e) {
								e.stopPropagation();
								let val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
								let regexr = '({search})';
								column.search(val != '' ? regexr.replace('{search}', '(((' + val + ')))') : '', val != '', val == '').draw();
							});
						});
					}
					if(filterColumsSelect.length>0) {
						this.api().columns(filterColumsSelect).every( function () {
							let column = this;
							let filterDataArray = [];
							let select = $('<select class="form-select form-select-sm table-filter"><option value=""></option></select>')
								.appendTo( $(column.header()) )
								.on( 'change', function () {
									let val = $.fn.dataTable.util.escapeRegex(
										$(this).val()
									);
									column
										.search( val ? '^'+val+'$' : '', true, false )
										.draw();
								});
							column.data().unique().sort().each( function ( d, j ) {
								let filterData = d;
								if(d.indexOf('</') !== -1) { // In case there is html tags we strip those...
									filterData = ($(d).length) ? $(d).text() : d;
									// console.warn(d+' - '+$(d));
								}
								// select.append( '<option value="'+filterData+'">'+filterData+'</option>' );
								if(filterData!='') filterDataArray.push(filterData);
							});
							// In case there was html tags we need to sort the data again
							filterDataArray.sort();
							filterDataArray.forEach(element => {
								select.append( '<option value="'+element+'">'+element+'</option>' );
							});
						});
					}
					/*
					// Using text in another row of header => needs to uncomment Add filter row in header line above
					var api = this.api();
					// For each column
					api.columns().eq(0).each(function (colIdx) {
							// Set the header cell to contain the input element
							var cell = $('.filters th').eq(
								$(api.column(colIdx).header()).index()
							);
							var title = $(cell).text();
							$(cell).html('<input class="form-control" type="text" placeholder="Rechercher" />');
							// On every keypress in this input
							$(
								'input',
								$('.filters th').eq($(api.column(colIdx).header()).index())
							)
							.off('keyup change')
							.on('keyup change', function (e) {
								e.stopPropagation();
								// Get the search value
								$(this).attr('title', $(this).val());
								var regexr = '({search})'; //$(this).parents('th').find('select').val();
								var cursorPosition = this.selectionStart;
								// Search the column for that value
								api
									.column(colIdx)
									.search(
										this.value != ''
											? regexr.replace('{search}', '(((' + this.value + ')))')
											: '',
										this.value != '',
										this.value == ''
									)
									.draw();
								$(this)
									.focus()[0]
									.setSelectionRange(cursorPosition, cursorPosition);
							});
					});
					// Using select in footer
					this.api().columns(filterColums).every( function () {
						let column = this;
						let select = $('<select class="form-select"><option value=""></option></select>')
							.appendTo( $(column.footer()).empty() )
							.on( 'change', function () {
								let val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
								column
									.search( val ? '^'+val+'$' : '', true, false )
									.draw();
							});
						column.data().unique().sort().each( function ( d, j ) {
							select.append( '<option value="'+d+'">'+d+'</option>' )
						});
					});
					*/
				}
			});
			// myTable.buttons().container().appendTo(myDiv+'_filter');
		}
		// if(globals.isMobile) myTable.fixedHeader.disable();
	},
	downloadFiles: function (link, name, thisBtn, event) {
		event.preventDefault();
		$(thisBtn).attr("disabled", true);
		const a = $("<a>").attr("href", link).attr("download", name).appendTo("footer");
		a[0].click();
		a.remove();
		$(thisBtn).attr("disabled", false);
	},
	resetFilters: function() {
		$('.table-filter').each(function() {
			$(this).val('');
		}).trigger('change');
	},
	calculateLines: function() {
		// Calculating totals per rows
		let tab_nbp = 0;
		let tab_tht = 0;
        const tbl = $('#tabProducts');
        tbl.find('tr').each(function () {
			// Calculating howmany items per line first
            let sum = 0;
            let howmany = 0;
			const qtyPack = Number($(this).find('.qtyPack').text());
            $(this).find('input[type=number]').each(function () {
                if (!isNaN(this.value) && this.value.length != 0) {
                    const packs = parseFloat(this.value);
                    howmany += packs*qtyPack;
                }
            });
            $(this).find('.nbp').text(howmany.toFixed(0));
			tab_nbp += howmany;
			// Then Calculating total price per line
            let price = Number($(this).find('.unitPrice').text());
            //let howmany = Number($(this).find('.nbp').text());
			if (!isNaN(price) && price.length != 0 && !isNaN(howmany) && price.howmany != 0) {
				sum = price * howmany;
			}
            $(this).find('.tht').text("$ "+sum.toFixed(2));
			const tht_rmb = sum * App.settings.rmbRate
            $(this).find('.tht_rmb').text(tht_rmb.toFixed(2)+" Y");
			tab_tht += sum;
        });
		$('.tab_nbp').text(tab_nbp);
		$('.tab_tht').text("$ "+tab_tht.toFixed(2));
		const tab_tht_rmb = tab_tht * App.settings.rmbRate
		$('.tab_tht_rmb').text(tab_tht_rmb.toFixed(2)+" Y");
		$('#total_o').val(tab_tht.toFixed(2));
	},
	setOrderPage: async function(orderType) {
		// Watch Out Client is owned by user (globals.id), Addresses are owned by clients (globals.auto_c)
		const generateSelectBoxes = await this.getFormsSelectBoxes(['#addOrderForm'], 'getClientAddressList', ['auto_c', 'auto_a']);
		const clientId = $('#addOrderForm #auto_c').val();
		const addressId = $('#addOrderForm #auto_a').val();
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, clientId: clientId, addressId: addressId, orderType: orderType, req: 'getOrderCatalog'}, function(data){
			$("#orderCatalogCont").empty().append(data.snippet);
			$("#clientAddressCont").empty().append(data.addressInvoice);
			$("#deliveryAddressCont").empty().append(data.addressDelivery);
		}, "json").done(function(data) {
			// App.buildDataTables('#dataTableClientsInterventionsList', 25, true, 0, 'desc', 'SNS-GPI Liste des interventions pour '+NomClient, [0,1,3,4], [2,5,6,7], '', '', '', [0,1,2,3,4,5,6,7]);
			let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
				showCounter : true,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				closeText : 'X'
			});
			lightbox.refresh();
			if(globals.type=='user') {
				$('.userReadOnly').attr('readonly', true);
				$('.userDisabled').attr('disabled', true);
			}
		});
	},
	refreshOrderFormDelivery: function(thisBtn, orderType) {
		const formId = '#'+$(thisBtn).closest('form').attr('id');
		const clientId = thisBtn.value;
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, clientId: clientId, req: 'refreshOrderFormDelivery'}, function(data){
			$(formId+ ' #auto_a').empty().append(data.snippet);
		}, "json").done(function(data) {
			App.refreshOrderForm(formId, orderType);
		});
	},
	refreshOrderForm: function(myFormDiv, orderType) {
		const clientId = $(myFormDiv+' #auto_c').val();
		const addressId = $(myFormDiv+' #auto_a').val();
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, clientId: clientId, addressId: addressId, orderType: orderType, req: 'getOrderCatalog'}, function(data){
			$("#orderCatalogCont").empty().append(data.snippet);
			$("#clientAddressCont").empty().append(data.addressInvoice);
			$("#deliveryAddressCont").empty().append(data.addressDelivery);
		}, "json").done(function(data) {
			let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
				showCounter : true,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				closeText : 'X'
			});
			lightbox.refresh();
		});
	},
	addOrder: function(myFormDiv)
	{
		if(Number($('#total_o').val()) > 0) {
			$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Please wait');
			// $('[disabled]').removeAttr('disabled'); // Getting back disabled fields
			let query = $(myFormDiv).serialize();
			let req = "addOrder";
			// Serializing checkBoxes first for regular checkBoxes, second for Bootstrap's customs (assuming id=name)...
			// let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.name+"="+this.checked;}).get().join("&");
			// query = query + "&" + checkBoxes + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
			query = query  + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
			let returns = "";
			$.post(globals.serverAddress, query, function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success text-center" role="alert"><b><i class="fa fa-2x fa-check-circle"></i> Your order #'+data.nameOrder+' is processing successfully<br>You can download your <a href="'+data.pdflink+'" target="_blank" download="'+data.pdfname+'" class="btn btn-danger btn-block btn-lg"><i class="fa fa-file-pdf"></i> ORDER FORM</a></div>';
					// const a = $("<a>").attr("href", data.pdflink).attr("download", data.pdfname).attr("target", "_blank").appendTo("footer");
					// a[0].click();
					// a.remove();
				}
				else
					returns = '<div class="alert alert-danger text-center" role="alert"><b><i class="fa fa-2x fa-times-circle"></i> Your order has probably not been proccessed, something went wrong.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-shopping-cart me-1"></i>Send Order');
				$(myFormDiv+' #successfail').empty().append(returns);
			});
		}
		else alert("This order is empty !!");
	},
	setUsersListPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, req: 'getUsersList'}, function(data){
			if(data.error=='') $("#userListCont").empty().append(data.snippet);
			else if(globals.type=='MyBelovedLord' && data.error=='administrator') setTimeout(function(){App.setUsersListPage();}, 1000); // Retry after some time => If an admin goes directly to this page then the token might not be set !
			else $("#userListCont").empty().append(data.snippet);
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableUsersList', 25, true, 0, 'asc', 'OZENA Users List', [0,1,2,3,4,5,7,8,9], [6], '', '', '', [0,1,2,3,4,5,6,7,8,9]);
		}).always(function(){
			App.getFormsSelectBoxes(['#addUserForm'], 'getCountriesList', ['country_c']);
		});
	},
	setUserPage: async function(userId) {
		if(userId==globals.id || globals.type!='user') { // If not regular user or own userId
			const myFormDiv = '#modUserForm';
			App.clearFormFields(myFormDiv);
			const generateSelectBoxes = await this.getFormsSelectBoxes([myFormDiv, '#modAddressForm', '#addAddressForm'], 'getCountriesList', ['country_a', 'country_c']);
			$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, auto_u: userId, req: 'fillModUser'}, function(data){
				if(data.ok=="ok") {
					for (const [key, value] of Object.entries(data.users)) {
						$(myFormDiv+' #'+key).val(value);
					}
					$("#addressListCont").empty().append(data.snippet);
					$('#addAddressForm #client_a').val(data.users.auto_c);
				}
				else if(data.administrator) {
					$(myFormDiv).closest('div').empty().append('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>You don\'t have permission to access this page !</div><hr><a href="/users/'+globals.id+'" class="btn btn-dark btn-lg btn-block shadow"><i class="fa fa-user-circle me-1"></i>Go to your account</a>');
				}
				else alert("Something went wrong, I haven't found this user !");
			}, "json").always(function(){
				App.buildDataTables('#dataTableAddressList', 25, true, 0, 'asc', 'OZENA Delivery address List', [0,1,2,3,4,5,7,8], [6], '', '', '', [0,1,2,3,4,5,6,7,8]);
			});
			if(globals.type=='user') {
				$('.userReadOnly').attr('readonly', true);
				$('.userDisabled').attr('disabled', true);
			}
		}
		else {
			$("#userPageBody").empty().append('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>You don\'t have permission to access this page !</div>');
			$("#userPageBody").append('<hr><a href="/users/'+globals.id+'" class="btn btn-dark btn-lg btn-block shadow"><i class="fa fa-user-circle me-1"></i>Go to your account</a>');
		}
	},
	fillModUser: function(auto_u, thisBtn, myFormDiv, myFormModal) {
		App.clearFormFields(myFormDiv);
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, auto_u: auto_u, req: 'fillModUser'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.users)) {
					$(myFormDiv+' #'+key).val(value);
				}
			}
			else if(data.administrator) {
				$(myFormDiv).closest('div').empty().append('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>You don\'t have permission to access this page !</div><hr><a href="/users/'+globals.id+'" class="btn btn-dark btn-lg btn-block shadow"><i class="fa fa-user-circle me-1"></i>Go to your account</a>');
			}
			else alert("Something went wrong, I haven't found this user !");
		}, "json").always(function(){
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
		});
	},
	fillModAddress: function(auto_a, thisBtn, myFormDiv, myFormModal) {
		App.clearFormFields(myFormDiv);
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, auto_a: auto_a, req: 'fillModAddress'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.address)) {
					$(myFormDiv+' #'+key).val(value);
				}
			}
			else alert("Something went wrong, I haven't found this user !");
		}, "json").always(function(){
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
		});
	},
	modUser: function(myFormDiv, fromModal)
	{
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Please wait');
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=modUser";
		let returns = "";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>This account has been successfully updated.</b></div>';
				if(fromModal) {
					App.setUsersListPage();
					setTimeout(function(){
						$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
						App.clearFormFields(myFormDiv);
					}, 2600);
				}
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>This account has not been updated because of a technical issue.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Save');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	addUser: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Please wait');
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=addUser";
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>The account has been created.</b></div>';
				App.setUsersListPage();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else if(data.error=='administrator') returns = data.snippet;
			else
				returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>This account has not been created because of a technical issue.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Save');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	delUser: function(myFormDiv, event) {
		event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce compte Client ?");
		if (confirmDeletion) {
			$(myFormDiv+' #deleter').attr("disabled", true);
			const delId = $(myFormDiv+' #auto_u').val();
			const req = "delClient";
			let query = "auto_u=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req;
			let returns = "";
			//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
			$.post(globals.serverAddress, query, function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Ce compte Client a bien été supprimée.</b></div>';
					App.setUsersListPage();
					setTimeout(function(){
						$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
						App.clearFormFields(myFormDiv);
					}, 1600);
				}
				else
					returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Ce compte Client n\'a pas été supprimée suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' #deleter').attr("disabled", false);
				$(myFormDiv+' #successfail').empty().append(returns);
			});
		}
	},
	modAddress: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Please wait');
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=modAddress";
		let returns = "";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>This address has been successfully updated.</b></div>';
				if(globals.type=='user') App.setUserPage(globals.id);
				else {
					const userId = $('#modUserForm #auto_u').val();
					App.setUserPage(userId);
				}
				setTimeout(function(){
					$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>This address has not been updated because of a technical issue.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Save');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	addAddress: function(myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Please wait');
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=addAddress";
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>The address has been created.</b></div>';
				if(globals.type=='user') App.setUserPage(globals.id);
				else {
					const userId = $('#modUserForm #auto_u').val();
					App.setUserPage(userId);
				}
				setTimeout(function(){
					$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else if(data.error=='administrator') returns = data.snippet;
			else
				returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>This address has not been created because of a technical issue.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Save');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	setAdminPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, req: 'getOrdersList'}, function(data){
			if(data.error=='') $("#orderListCont").empty().append(data.snippet);
			else if(globals.type=='MyBelovedLord' && data.error=='administrator') setTimeout(function(){App.setAdminPage();}, 1000); // Retry after some time => If an admin goes directly to this page then the token might not be set !
			else $("#orderListCont").empty().append(data.snippet);
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableOrdersList', 25, true, 19, 'desc', 'OZENA Orders List', [0,3,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24], [1,2,4,25], '', '', '', [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25], false);
		}).always(function(){
			// App.getFormsSelectBoxes(['#modUserForm'], 'usersController', ['site_u']);
		});
		const litepickerSingleDispatch = document.querySelectorAll('#date_dispatch_o');
		if(litepickerSingleDispatch.length>0) {
			litepickerSingleDispatch.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'en-EN',
					format: 'DD/MM/YYYY'
				});
			});
		}
		const litepickerRangeDelivery = document.querySelectorAll('#date_delivery_o');
		if (litepickerRangeDelivery.length>0) {
			litepickerRangeDelivery.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					// startDate: new Date(),
					// endDate: new Date(),
					singleMode: false,
					numberOfMonths: 2,
					numberOfColumns: 2,
					format: 'DD/MM/YYYY',
					delimiter: ' > ',
					lang: 'fr-FR',
					tooltipText: {
						one: 'day',
						other: 'days'
					},
					tooltipNumber: (totalDays) => {
						return totalDays;
					},
					plugins: ['ranges'],
					ranges: {
						// customRangesLabels:["Today","Tomorrow","Next 7 days","Next 30 days","This Month","Next Month"],
						customRanges: {
							'Today': [globals.today, globals.today], // first start date then end date.
							'Tomorrow': [globals.tomorrow, globals.tomorrow],
							'Next 7 days': [globals.tomorrow, globals.nextSevenDays],
							'Next 30 days': [globals.tomorrow, globals.nextThirtyDays],
							'This Month': [globals.thisMonthStart, globals.thisMonthEnd],
							'Next Month': [globals.nextMonthStart, globals.nextMonthEnd],
						},
					},
				});
			});
		}
	},
	setDeliveryPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, req: 'getDeliveriesList'}, function(data){
			$("#deliveriesListCont").empty().append(data.snippet);
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableDeliveriesList', 25, true, 6, 'desc', 'OZENA Deliveries List', [0,3,5,6,7,8], [1,2,4,9], '', '', '', [0,1,2,3,4,5,6,7,8,9]);
		}).always(function(){
			// App.getFormsSelectBoxes(['#modUserForm'], 'usersController', ['site_u']);
		});
		const litepickerSingleDispatch = document.querySelectorAll('#date_dispatch_o');
		if(litepickerSingleDispatch.length>0) {
			litepickerSingleDispatch.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					lang: 'en-EN',
					format: 'DD/MM/YYYY'
				});
			});
		}
		const litepickerRangeDelivery = document.querySelectorAll('#date_delivery_o');
		if (litepickerRangeDelivery.length>0) {
			litepickerRangeDelivery.forEach(function(thisItem) {
				new Litepicker({
					element: thisItem,
					// startDate: new Date(),
					// endDate: new Date(),
					singleMode: false,
					numberOfMonths: 2,
					numberOfColumns: 2,
					format: 'DD/MM/YYYY',
					delimiter: ' > ',
					lang: 'fr-FR',
					tooltipText: {
						one: 'day',
						other: 'days'
					},
					tooltipNumber: (totalDays) => {
						return totalDays;
					},
					plugins: ['ranges'],
					ranges: {
						// customRangesLabels:["Today","Tomorrow","Next 7 days","Next 30 days","This Month","Next Month"],
						customRanges: {
							'Today': [globals.today, globals.today], // first start date then end date.
							'Tomorrow': [globals.tomorrow, globals.tomorrow],
							'Next 7 days': [globals.tomorrow, globals.nextSevenDays],
							'Next 30 days': [globals.tomorrow, globals.nextThirtyDays],
							'This Month': [globals.thisMonthStart, globals.thisMonthEnd],
							'Next Month': [globals.nextMonthStart, globals.nextMonthEnd],
						},
					},
				});
			});
		}
	},
	popConfirmOrder: function (auto_o, idOrder) {
		$('#confirmOrderModal #auto_o').val(auto_o);
		$('#modalTitleConfirmOrderId').text("#"+idOrder);
		let myModal = new bootstrap.Modal(document.getElementById('confirmOrderModal'));
		myModal.show();
	},
	confirmOrder: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Please wait');
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=confirmOrder";
		let returns = "";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>This Order has been confirmed.</b></div>';
				if($.sessionStorage.getItem('myPage')=='admin') App.setAdminPage();
				else App.setDeliveryPage();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>This Order has not been confirmed because of a technical issue.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Confirm Order');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	popTrackingOrder: function (auto_o, idOrder, date_dispatch_o, date_delivery_o, tracking_o) {
		$('#trackingOrderModal #auto_o').val(auto_o);
		$('#trackingOrderModal #date_dispatch_o').val(date_dispatch_o);
		$('#trackingOrderModal #date_delivery_o').val(date_delivery_o);
		$('#trackingOrderModal #tracking_o').val(tracking_o);
		$('#modalTitleTrackingOrderId').text("#"+idOrder);
		let myModal = new bootstrap.Modal(document.getElementById('trackingOrderModal'));
		myModal.show();
	},
	trackingOrder: function(myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Please wait');
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=trackingOrder";
		let returns = "";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>This Order has been confirmed.</b></div>';
				if($.sessionStorage.getItem('myPage')=='admin') App.setAdminPage();
				else App.setDeliveryPage();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>This Order has not been confirmed because of a technical issue.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Save Information');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	invoiceOrder: function(auto_o, thisBtn)
	{
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		const query = "id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&auto_o=" + auto_o + "&req=invoiceOrder";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				alert("Commercial invoice has been successfully generated and sent to the customer.");
			}
			else alert("Something went wrong !\r\nCommercial invoice has not been successfully generated and sent to the customer.");
		}, "json").always(function(data){
			$(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-file-invoice-dollar"></i>');
		});
	},
	popDetailsOrder: function (auto_o, idOrder, thisBtn) {
		$('#detailsOrderModal #auto_o').val(auto_o);
		$('#modalTitleDetailsOrderId').text("#"+idOrder);
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>');
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, auto_o: auto_o, auto_c: globals.auto_c, req: 'getDetailsOrderList'}, function(data){
			$("#detailsOrderCont").empty().append(data.snippet);
		}, "json").done(function(data) {
			// App.buildDataTables('#dataTableDetailsOrderList', 25, true, 0, 'desc', 'C&P Architecture Liste des Projets', [1,2,3,4,5,6,7,8,9,10,11,12,13], [0], '', '', '', [0,1,2,3,4,5,6,7,8,9,10,11,12,13]);
		}).always(function(data) {
			$(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-tasks"></i>');
			let myModal = new bootstrap.Modal(document.getElementById('detailsOrderModal'));
			myModal.show();
		});
	},
	markAsPayed: function(thisCheckBox, auto_o, payment)
	{
		const payed = thisCheckBox.checked;
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&auto_o=" + auto_o + "&payment=" + payment + "&payed=" + payed + "&req=markAsPayed";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") console.log('Action succeeded.');
			else {
				alert('Action failed !');
				thisCheckBox.checked = !payed;
			}
		}, "json").fail(function(jqXHR, textStatus, errorThrown){
			alert('Action failed !.\n'+textStatus+'\n'+errorThrown);
			thisCheckBox.checked = !payed;
		});
	},
	delOrder: function(auto_o, thisBtn) {
		// event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Are you absolutely positive you want to delete this order ?");
		if (confirmDeletion) {
			$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
			const req = "delOrder";
			let query = "auto_o=" + auto_o + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req;
			$.post(globals.serverAddress, query, function(data){
				if(data.ok=="ok") {
					alert('This order has been deleted successfully.');
					if($.sessionStorage.getItem('myPage')=='admin') App.setAdminPage();
					else App.setDeliveryPage();
				}
				else
					alert('This order has not been deleted because of a technical error.');
			}, "json").always(function(data){
				$(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-times-circle"></i>');
			});
		}
	},
	makeNiceDateTime: function(d) {
		const jsDay = d.getDate();
		const jsMonth = d.getMonth()+1; // January is zero
		const jsHours = d.getHours();
		const jsMinutes = d.getMinutes();
		const jsSeconds = d.getSeconds();
		const niceDay = (jsDay<10) ? '0'+jsDay : jsDay;
		const niceHours = (jsHours<10) ? '0'+jsHours : jsHours;
		const niceMinutes = (jsMinutes<10) ? '0'+jsMinutes : jsMinutes;
		const niceSeconds = (jsSeconds<10) ? '0'+jsSeconds : jsSeconds;
		const niceMonth = (jsMonth<10) ? '0'+jsMonth : jsMonth;
		const niceDate = niceDay+'/'+niceMonth+'/'+d.getFullYear()+' '+niceHours+':'+niceMinutes+':'+niceSeconds;
		return niceDate;
	},
	makeNiceTime: function(timeInMinutes) {
		const jsHours = Math.floor(timeInMinutes/60);
		const jsMinutes = timeInMinutes-jsHours*60;
		console.debug(jsMinutes);
		const niceHours = (jsHours<10) ? '0'+jsHours : jsHours;
		const niceMinutes = (jsMinutes<10) ? '0'+jsMinutes : jsMinutes;
		const niceTime = niceHours+'h'+niceMinutes;
		return niceTime;
	},
	makeNiceTime24: function(timeInMinutes, offset) {
		const tempDate = new Date(Math.floor(timeInMinutes*60*1000)); // UNIX Timestamp here is GMT+1 Winter time so an hour is added
		const jsHours = Math.floor(tempDate.getHours()-offset);
		const jsMinutes = tempDate.getMinutes();
		const niceHours = (jsHours<10) ? '0'+jsHours : jsHours;
		const niceMinutes = (jsMinutes<10) ? '0'+jsMinutes : jsMinutes;
		const niceTime = niceHours+'h'+niceMinutes;
		console.warn(tempDate+' - '+niceTime+' - '+Math.floor(timeInMinutes*60*1000));
		return niceTime;
	},
	convertStringToDate: function(niceDateTime) { // See makeNiceDateTime => convertStringToDate("19/04/2022 11:20:30")
		let dateComponents = niceDateTime.split(' ');
		let datePieces = dateComponents[0].split("/");
		let timePieces = dateComponents[1].split(":");
		return(new Date(datePieces[2], (datePieces[1] - 1), datePieces[0], timePieces[0], timePieces[1], timePieces[2]));
	},
	printDiv: function(divName){
		var printContents = document.getElementById(divName).innerHTML;
		var originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();
		document.body.innerHTML = originalContents;
	},
	btnCheckThatBox: function(thisBtn) {
		const box2Check = $(thisBtn).prev('input[type="checkbox"]');
		let state = $(box2Check).is(':checked');
		state = !state;
		$(box2Check).attr('checked', state);
	},
	fullScreen: function(){
		$('body').addClass('sidenav-toggled');
		$('#sidebarToggle').html('<i class="fa fa-2x fa-bars"></i>');
		$('header').hide();
		$('#mainContent').removeClass('mt-n10');
		$('#fullScreenBack').show();
	},
	fullScreenBack: function(){
		if(localStorage.getItem('sb|sidebar-toggle') === 'false') {
			$('body').removeClass('sidenav-toggled');
			$('#sidebarToggle').html('<i class="fa fa-2x fa-angle-double-left"></i>');
		}
		$('header').show();
		$('#mainContent').addClass('mt-n10');
		$('#fullScreenBack').hide();
	},
	//below taken from http://www.howtocreate.co.uk/tutorials/javascript/browserwindow
	getScrollXY: function () {
		var scrOfX = 0, scrOfY = 0;
		if( typeof( window.pageYOffset ) == 'number' ) {
			//Netscape compliant
			scrOfY = window.pageYOffset;
			scrOfX = window.pageXOffset;
		} else if( document.body && ( document.body.scrollLeft || document.body.scrollTop ) ) {
			//DOM compliant
			scrOfY = document.body.scrollTop;
			scrOfX = document.body.scrollLeft;
		} else if( document.documentElement && ( document.documentElement.scrollLeft || document.documentElement.scrollTop ) ) {
			//IE6 standards compliant mode
			scrOfY = document.documentElement.scrollTop;
			scrOfX = document.documentElement.scrollLeft;
		}
		return [ scrOfX, scrOfY ];
	},
	//taken from http://james.padolsey.com/javascript/get-document-height-cross-browser/
	getDocHeight: function () {
		var D = document;
		return Math.max(
			D.body.scrollHeight, D.documentElement.scrollHeight,
			D.body.offsetHeight, D.documentElement.offsetHeight,
			D.body.clientHeight, D.documentElement.clientHeight
		);
	},
};

// Expose App object to window object => need this to use click event in html
window.App = App;
require('./router.js');

(function() {
	// If not logged in and page is not login redirects to login page...
	if ($.localStorage.getItem('pass')!='OK' && document.URL.indexOf( 'login' ) === -1)
	{
		document.location.href='/login';
	}
	else if (document.URL.indexOf( 'login' ) !== -1) {
		// It's login page.
		App.init();
	}
	else {
		// pass ok...
		App.init();
	}
})();